import axios from "axios";
import { useState } from "react";

const Contact = () => {
  const [form, setform] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://realestatebackend.cloud-dynasty.com/api/contact_store", form)
      .then((res) => {
      });
  };
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setform((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <>
      <section className="l-contactus" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <form onSubmit={handleSubmit}>
                <div className="l-form">
                  <h2>Contact our team</h2>
                  <div className="input-box">
                    <div className="input">
                      <label htmlFor="fName">First Name</label>
                      <input
                        value={form.firstname}
                        type="text"
                        id="fName"
                        name="firstname"
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="input">
                      <label htmlFor="LName">Last Name</label>
                      <input
                        value={form.lastname}
                        type="text"
                        id="lName"
                        name="lastname"
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input">
                      <label htmlFor="wEmail">Work email</label>
                      <input
                        value={form.email}
                        type="email"
                        id="wEmail"
                        name="email"
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input">
                      <label htmlFor="number">Phone number</label>
                      <input
                        value={form.number}
                        type="number"
                        id="number"
                        name="number"
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="input">
                      <label htmlFor="help">How can our team help you?</label>
                      <textarea
                        value={form.message}
                        id="help"
                        rows="5"
                        name="message"
                        onChange={handleInputs}
                      ></textarea>
                    </div>
                  </div>
                  <button type="submit" className="l-getStarted">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="col-6">
              <div className="p-5">
                <h3>
                  Align, collaborate and gain visibility into your work in one
                  connected space
                </h3>
                <ul className="fire-list">
                  <li>
                    Meet with a product consultant to see how velo pixel can fit
                    your exact business needs
                  </li>
                  <li>
                    Explore our tailored pricing plans based on your goals and
                    priorities
                  </li>
                  <li>
                    Boost productivity from day one by building your team's
                    ideal workflow
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
