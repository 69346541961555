import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Component/Admin/Pages/Home";
import Propertiescreate from "./Component/Admin/Pages/Propertiescreate";
import PropertiesUpdate from "./Component/Admin/Pages/PropertiesUpdate";
import Profile from "./Component/Admin/Pages/Components/Profile";
import Calander from "./Component/Admin/Pages/Calander";
import Register from "./Component/User/Register";
import Login from "./Component/User/Login";
import "animate.css";
import ForgotPassword from "./Component/User/ForgotPassword";
import ChangePassword from "./Component/User/ChangePassword";
import Index from "./Component/LandingPage/Index";
import Pricing from "./Component/LandingPage/Pricing";

const App = () => {
  var userId = sessionStorage.getItem("userId");

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/dashboard" component={userId ? Home : Login} />
          <Route
            exact
            path="/propertiescreate"
            component={userId ? Propertiescreate : Login}
          />
          <Route
            exact
            path="/propertiesupdate/:id"
            component={userId ? PropertiesUpdate : Login}
          />
          <Route exact path="/calander" component={userId ? Calander : Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/changepassword" component={ChangePassword} />
          <Route exact path="/profile" component={userId ? Profile : Login} />
          <Route exact path="/pricing" component={userId ? Pricing : Login} />
          <Route exact path="/login" component={userId ? Home : Login} />
          <Route exact path="/" component={Index} />
          {/* <Route exact path="/" component={userId ? Home : Login} /> */}
        </Switch>
      </Router>
    </div>
  );
};

export default App;