export const initialstate = false;

export const calend_reducer = (state, action) =>{

    switch (action.type) {
        case 'true':
            return !state;
        case 'false':
            return !state;
        default:
            return !state;
    }
}