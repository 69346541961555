import axios from "axios";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Price from "../../../LandingPage/Pricing.js";

const Profile = () => {
  const [price, setPrice] = useState(false);
  
  const[name, setname] = useState();
  const[email, setEmail] = useState();
  const[portfolioLink, setportfolioLink] = useState();
  const[bio, setBio] = useState();
  const[image, setImage] = useState();
  const[userId, setuserId] = useState();
  const[propertyCount, setpropertyCount] = useState();
  const [planname, setplanname] = useState();

  var answer = window.location.href;
  const answer_array = answer.split("/");
  let history = useHistory();

  if (answer_array[2] == "localhost:3000") {
    var url = "http://localhost:8000/api/get_profile";
    var imageurl = "http://localhost:8000";
  } else {
    var url = "https://realestatebackend.cloud-dynasty.com/api/get_profile";
    var imageurl = "https://realestatebackend.cloud-dynasty.com";
  }

  React.useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    axios.post(url, {'userId' :userId}).then((res) => {
      setname(res.data.data.name);
      setEmail(res.data.data.email);
      setBio(res.data.data.bio);
      setportfolioLink(res.data.data.link);
      setImage(res.data.data.image);
      setpropertyCount(res.data.property_count);
      setplanname(res.data.plan_name[0]);
    });
  }, []);
const handlePrice = () => setPrice(!price);
const update_profile = (e) =>{
  e.preventDefault();
  const userId = sessionStorage.getItem('userId');
  if (answer_array[2] == "localhost:3000") {
    var update_profile = `http://localhost:8000/api/profile_update/${userId}`;
  } else {
    var update_profile = `https://realestatebackend.cloud-dynasty.com/api/profile_update/${userId}`;
  }
  const data = new FormData(e.target);
  axios.post(update_profile, data).then((res) => {
    if (res.data.Status == 1) {
      toast.success('Profile updated successfully !', { position: toast.POSITION.TOP_CENTER })
      setTimeout(function () {
        setname(res.data.data.name);
        setEmail(res.data.data.email);
        setBio(res.data.data.bio);
        setportfolioLink(res.data.data.link);
        setImage(res.data.data.image);
      }, 2000);
    }
    else {
      toast.error('profile has been not updated!',{position: toast.POSITION.TOP_CENTER})
    }
  });
}
  return (
    <section className="container custom">
      <ToastContainer autoClose={3000} />
      <h1 className="profile"></h1>
      <div className="row">
        <div className=" col-md-6">
          <div className="left">
            <div className="img">
              <img
                src={`${imageurl}/images/profile/${image}`}
                alt="simpsonImg"
              />
            </div>
            {/* <h1>{name}</h1> */}
            <div className="line"></div>
            {/* <h3>Active Plan : {planname} </h3>
            <h3>Property Count : {propertyCount}  </h3> */}
            <div className="cards">
              <div className="card">
                <h5>Active Plan</h5>
                <h5 className="circle">{planname}</h5>
              </div>
              <div className="card">
                <h5>Property Count</h5>
                <h5 className="circle">{propertyCount}</h5>
              </div>
            </div>
            <div>
              <button className="changeplan" onClick={handlePrice}>CHANGE PLAN</button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="right">
            {/* <h4 className="dark mb-4" data-photo>
              Profile photo
            </h4> */}
            <form onSubmit={update_profile}>
              <div className="fileDiv">
                <label for="file" className="fileBtn">
                  Upload Image
                </label>
                <input type="file" name="image" id="file"/>
              </div>
              {/* <h2 className="dark">Account info</h2> */}
              <div className="input">
                <label for="name">Display name</label>
                <input
                  name="name"
                  required
                  type="text"
                  id="name"
                  minlength="4"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="input">
                <label for="email">E-mail</label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  required
                  minlength="1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input">
                <label for="portfolio_link">link to portfolio</label>
                <input type="text" id="portfolio_link" name="portfolio_link" 
                  value={portfolioLink}
                  onChange={(e) => setportfolioLink(e.target.value)}
                />
              </div>
              <div className="input">
                <label for="bio">bio</label>
                <textarea type="text" name="bio" rows="3" value={bio} onChange={(e) => setBio(e.target.value)}>
                  </textarea>
                <input type="text" className="d-none" name="image"
                  onChange={(e) => setImage(e.target.value)} />
              </div>
              <button
                className="update {{ disable ? 'disabled' : ''}}"
                type="submit"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
      <Price
        isDashboard="customClass"
        handleShow={handlePrice}
        setShow={price}
      />
    </section>
  );
};

export default Profile;
