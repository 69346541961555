const Garantee = () => {
  return (
    <>
      <section className="guarantee">
        <div className="container">
          <h2>We Guarantee</h2>
          <div className="g-cards">
            <div className="g-card-content purple">
            <i className="fa fa-plus" aria-hidden="true"></i>
              <h3>Safe and Secured</h3>
              <p>
                We offer 24/7 support, in-house field engineers, and our own
                developers.
              </p>
            </div>
            <div className="g-card-content orange">
            <i className="fa fa-hourglass-start" aria-hidden="true"></i>
              <h3>99.9% Uptime Guarantee</h3>
              <p>
                With 7+ years in the IT inductry we have the experience
                you can depend on.
              </p>
            </div>
            <div className="g-card-content green">
            <i className="fa fa-comments" aria-hidden="true"></i>
              <h3>Dedicated Support</h3>
              <p>
                We have a platform that accommodates small, medium and
                entreprises needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Garantee;
