import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Price from "../../../LandingPage/Pricing.js";
import Swal from 'sweetalert2'

const Cards = () => {
  // =================================================== For change url ========================================================
  var answer = window.location.href;

  const answer_array = answer.split("/");

  let history = useHistory();

  const [propertyState, setpropertyState] = useState("");
  const [price, setPrice] = useState(false);

  React.useEffect(() => {
    get_updated_status();
    setTimeout(function () {
      setpropertyState(sessionStorage.getItem("state"));
      setTimeout(function () {
        var check_for_popup = sessionStorage.getItem("state");
        if (check_for_popup == 'false'){
            Swal.fire({
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              title: 'Welcome to realestate',
              text: "If you don't have any subscription so, plase choose the right plan for your team",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Add plan!'
            }).then((result) => {
              if (result.isConfirmed) {
                handlePrice();
              }
            })
          }
        }, 2000);
    },800);
  }, []);

  const get_updated_status = () => {

    const userId = sessionStorage.getItem("userId");

    if (answer_array[2] == "localhost:3000") {
      var get_status = `http://localhost:8000/api/updated_status/${userId}`;
    } else {
      var get_status = `https://realestatebackend.cloud-dynasty.com/api/updated_status/${userId}`;
    }

    fetch(get_status)
      .then((res) => res.json())
      .then((json) => {
        sessionStorage.setItem("state", json.state);
      });
  }
  // ===================================================== For logout ==========================================================
  const handlePrice = () => setPrice(!price);
  const logout = () => {
    if (answer_array[2] == "localhost:3000") {
      var logout = "http://localhost:8000/api/logout";
    } else {
      var logout = "https://realestatebackend.cloud-dynasty.com/api/logout";
    }

    axios.get(logout).then((res) => {
      if (res.data.Status == 1) {
        toast.success("Logout Successfully !", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(function () {
          sessionStorage.removeItem("userId");
          sessionStorage.removeItem("state");
          history.push("/");
          window.location.reload();
        }, 1000);
      } else {
      }
    });
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="infos ">
        <div className="info row py-3">
          <div className="col-lg-6 col-md-6 col-6">
            <h2>DASHBOARD</h2>
            <p>Welcome to VeloPixel Panel</p>
          </div>
          <div className="buttons col-lg-6 col-md-6 col-6  ">
            {propertyState === "false" ? (
              <a onClick={handlePrice}>
                <button className=" border-gradient border-gradient-purple mr-4">
                  + Add Property
                </button>
              </a>
            ) : null}
            {propertyState === "true" ? (
              <Link to="/propertiescreate">
                <button className=" border-gradient border-gradient-purple mr-4">
                  + Add Property
                </button>
              </Link>
            ) : null}

            <Link to='/profile'>
              <button className=" border-gradient border-gradient-purple mr-4">
                Profile
              </button>
            </Link>
            <a>
              <button
                className=" border-gradient border-gradient-purple mr-4"
                onClick={logout}
              >
                Logout
              </button>
            </a>
            {/* <Link to="/propertiescreate">
              <button className=" border-gradient border-gradient-purple">
                + Get Financing
              </button>
            </Link> */}
          </div>
        </div>

        <div className="cards">
          <div className="card">
            <p>Est. Portfolio Value</p>
            <h5>$10,425,497</h5>
          </div>
          <div className="card">
            <p>Monthly Rent Roll</p>
            <h5>$100,425 </h5>
          </div>
          <div className="card">
            <p> Mortgage Debt</p>
            <h5>$1,425,497</h5>
          </div>
          <div className="card">
            <p>Monthly Mortgage Payments</p>
            <h5>$10,654</h5>
          </div>
          <div className="card">
            <p>Portfolio Avg. Rate</p>
            <h5>5.66%</h5>
          </div>
          <div className="card">
            <p>Lenders Rate</p>
            <h5>8.67%</h5>
          </div>
          <div className="card">
            <p>Treasury Rate</p>
            <h5>2.56%</h5>
          </div>
          <div className="card">
            <p>Properties</p>
            <h5>54</h5>
          </div>
        </div>
      </div>
      <Price
        isDashboard="customClass"
        handleShow={handlePrice}
        setShow={price}
      />
    </>
  );
};

export default Cards;
