import Footer from "./Footer"
import Main from "./Main"
import Navbar from "./Navbar"

const Index = () => {
  return (
    <>
    <Navbar/>
    <Main/>
    <Footer/>
    </>
  )
}

export default Index