const Steps = () => {
  return (
    <>
      <section
        className="stratergy position-relative non-profits mt-3"
        id="work"
      >
        <div className={`triangle-border`}></div>
        <h2 className="workH2">HOW IT WORKS?</h2>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "Images/image1.png"}
                  alt="Graphics"
                />
              </div>
            </div>
            <div className="col-6">
              <p>Step 1</p>
              <h4>Easy payment.</h4>
              <ul className="fire-list">
                <li>
                  To help you make the best decision for your project, we have
                  provided a variety of different plans to choose from.
                </li>
                <li>
                  Each plan has different features that can help you achieve
                  your project goals.
                </li>
                <li>
                  Feel free to browse through our available plans and choose the
                  one that meets your requirements
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-6">
              <p>Step 2</p>
              <h4>Signup.</h4>
              <ul className="fire-list">
                <li>Register yourself choosing a plan and start working on your project</li>
                <li>Your credentials and personal information are kept confidential.</li>
                <li>We provide developers with round-the-clock support.</li>
              </ul>
            </div>
            <div className="col-6">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "Images/image1.png"}
                  alt="Graphics"
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-6">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "Images/image1.png"}
                  alt="Graphics"
                />
              </div>
            </div>
            <div className="col-6">
              <p>Step 3</p>
              <h4>Start managing properties.</h4>
              <ul className="fire-list">
                <li>Manage your properties easily and securely</li>
                <li>
                  Manage your properties with a real estate management system
                </li>
                <li>
                  Its a powerful tool that allows users to see important
                  information at a glance.
                </li>
                <li>
                  It can be used to monitor important systems and track progress
                  on projects.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps;
