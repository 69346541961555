import { Link } from "react-router-dom";
import Contact from "./Contact";
import Garantee from "./Garantee";
import Pricing from "./Pricing";
import Steps from "./Steps";

const Main = () => {
  return (
    <>
      <section className="hero">
        <div className="container pt-5">
          <h1 className="heroh1">
            A platform built for <br />
            managing your real state properties.
          </h1>
          <h3 className="heroh3">What would you like to manage with Velo?</h3>
          <div className="l-container">
            <div className="l-box green">
              <label htmlFor="code">
                <i className="fa fa-building" aria-hidden="true"></i>
                <p>Manage</p>
                <p>Properties</p>
              </label>
            </div>
            <div className="l-box purple">
              <label htmlFor="marketing">
                <i className="fa fa-bullhorn"></i>
                <p>Mortgage</p>
                <p>Debt</p>
              </label>
            </div>
            <div className="l-box orange">
              <label htmlFor="project">
                <i className="fa fa-pie-chart"></i>
                <p>Manage </p>
                <p> Payments</p>
              </label>
            </div>
            <div className="l-box darkBlue">
              <label htmlFor="operation">
                <i className="fa fa-gears"></i>
                <p>Manage</p>
                <p>Portfolio</p>
              </label>
            </div>
          </div>
          <Link to="/register">
            <button className="l-getStarted">
              Get Started
              <i className="fa fa-arrow-right ms-3"></i>
            </button>
          </Link>
        </div>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/Images/image3.png"}
            alt="dashboard"
          />
        </div>
      </section>
      <Steps />
      <Garantee />
      <Contact />
      <Pricing setShow="true" isDashboard="d-none"/>
    </>
  );
};

export default Main;
