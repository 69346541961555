import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Navbar = () => {
  const [dropdown, setdropdown] = useState({
    product: false,
    cases: false,
    feature: false,
    resource: false,
  });
  const [nav, setnav] = useState(false);
  const [stick, setstick] = useState(false);

  const handleDropdown = (e) => {
    if (e.target.id == "product") {
      setdropdown({
        product: true,
        cases: false,
        feature: false,
        resource: false,
      });
      if (dropdown.product == true) {
        setdropdown({ product: false });
      }
    }
    if (e.target.id == "cases") {
      setdropdown({
        product: false,
        cases: true,
        feature: false,
        resource: false,
      });
      if (dropdown.cases == true) {
        setdropdown({ cases: false });
      }
    }
    if (e.target.id == "feature") {
      setdropdown({
        product: false,
        cases: false,
        feature: true,
        resource: false,
      });
      if (dropdown.feature == true) {
        setdropdown({ feature: false });
      }
    }
    if (e.target.id == "resource") {
      setdropdown({
        product: false,
        cases: false,
        feature: false,
        resource: true,
      });
      if (dropdown.resource == true) {
        setdropdown({ resource: false });
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 10) {
        setstick(true);
      } else {
        setstick(false);
      }
    });
  }, []);

  return (
    <>
      <header className={`${stick ? "fixed" : ""}`}>
        <nav className="navs">
          <div className="hamDiv">
            <div>
              <Link to="/">
                <img
                  src={process.env.PUBLIC_URL + "/Images/logo.svg"}
                  alt="logo"
                />
              </Link>
            </div>
            <button className="hamburger" onClick={() => setnav(!nav)}>
              <i className="fa fa-bars"></i>
            </button>
          </div>
          <div className={`navbars ${nav ? "active" : ""}`}>
            <ul role="list" className="ul">
              <li className="navList webLogo">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/Images/logo.svg"}
                    alt="logo"
                  />
                </Link>
              </li>
              <li
                className={`navList ${dropdown.product ? "active" : ""}`}
                id="product"
                onClick={handleDropdown}
              >
                <a href="#">Home</a>
              </li>
              <li
                className={`navList ${dropdown.cases ? "active" : ""}`}
                id="cases"
                onClick={handleDropdown}
              >
                <a href="#work">About</a>
              </li>
              <li
                className={`navList ${dropdown.feature ? "active" : ""}`}
                id="feature"
                onClick={handleDropdown}
              >
                <a href="#price">Pricing</a>
              </li>
              <li
                className={`navList ${dropdown.resource ? "active" : ""}`}
                id="resource"
                onClick={handleDropdown}
              >
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <ul role="list">
              <li className="navList">
                <Link to="/login"> Login </Link>
              </li>
              <Link to="/register">
                <li className="navList getStarted">
                  Get Started
                  <i className="fa fa-arrow-right ms-1"></i>
                </li>
              </Link>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
