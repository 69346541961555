import axios from "axios";
import { useState } from "react";
import Swal from 'sweetalert2'
const Pricing = ({ isDashboard, handleShow, setShow }) => {
  const silverplan = () => {
    var answer = window.location.href;
    const answer_array = answer.split("/");
    if (answer_array[2] == "localhost:3000") {
      var plan = `http://localhost:8000/api/plan_create`;
    } else {
      var plan = `https://realestatebackend.cloud-dynasty.com/api/plan_create`;
    }
    const userId = sessionStorage.getItem("userId");
    const data = {
      plan_name:'silver',
      userId: userId
    };
    axios
      .post(plan, data)
      .then((res) => {
        if (res.data.status === '1'){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your new plan is activated successfully',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(function () {
            document.getElementById("closeprice").click();
            window.location.reload();
          },2200)
        } else if (res.data.status === '0'){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        } else if (res.data.status === '2') {
          Swal.fire({
            title: `Your ${res.data.plan_name} plan is not expired`,
            text: "Are you sure you want to renew?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add it!'
          }).then((result) => {
            if (result.isConfirmed) {
              if (answer_array[2] == "localhost:3000") {
                var plan = `http://localhost:8000/api/add_plan`;
              } else {
                var plan = `https://realestatebackend.cloud-dynasty.com/api/add_plan`;
              }
              const userId = sessionStorage.getItem("userId");

              const data = {
                plan_name: 'silver',
                userId: userId
              };

              axios
                .post(plan, data)
                .then((res) => {
                  Swal.fire(
                    'activated',
                    'Your new plan is activated successfully',
                    'success'
                  )
                setTimeout(function () {
                  document.getElementById("closeprice").click();
                  window.location.reload();
                },2200)
                });
            }
          })
        }
      });
  }
  const goldplan = () => {
    var answer = window.location.href;
    const answer_array = answer.split("/");
    if (answer_array[2] == "localhost:3000") {
      var plan = `http://localhost:8000/api/plan_create`;
    } else {
      var plan = `https://realestatebackend.cloud-dynasty.com/api/plan_create`;
    }
    const userId = sessionStorage.getItem("userId");

    const data = {
      plan_name: 'gold',
      userId: userId
    };

    axios
      .post(plan, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === '1') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your new plan is activated successfully',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(function () {
            document.getElementById("closeprice").click();
            window.location.reload();
          },2200)
        } else if (res.data.status === '0') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        } else if (res.data.status === '2') {
          Swal.fire({
            title: `Your ${res.data.plan_name} plan is not expired`,
            text: "Are you sure you want to renew?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add it!'
          }).then((result) => {
            if (result.isConfirmed) {
              if (answer_array[2] == "localhost:3000") {
                var plan = `http://localhost:8000/api/add_plan`;
              } else {
                var plan = `https://realestatebackend.cloud-dynasty.com/api/add_plan`;
              }
              const userId = sessionStorage.getItem("userId");

              const data = {
                plan_name: 'gold',
                userId: userId
              };

              axios
                .post(plan, data)
                .then((res) => {
                  Swal.fire(
                    'activated',
                    'Your new plan is activated successfully',
                    'success'
                  )
                  setTimeout(function () {
                    document.getElementById("closeprice").click();
                    window.location.reload();
                  },2200)
                });
            }
          })
        }
      });
  }
  const platinumplan = () => {
    var answer = window.location.href;
    const answer_array = answer.split("/");
    if (answer_array[2] == "localhost:3000") {
      var plan = `http://localhost:8000/api/plan_create`;
    } else {
      var plan = `https://realestatebackend.cloud-dynasty.com/api/plan_create`;
    }
    const userId = sessionStorage.getItem("userId");

    const data = {
      plan_name: 'platinum',
      userId: userId
    };

    axios
      .post(plan, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === '1') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your new plan is activated successfully',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(function () {
            document.getElementById("closeprice").click();
            window.location.reload();
          },2200)
        } else if (res.data.status === '0') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        } else if (res.data.status === '2') {
          Swal.fire({
            title: `Your ${res.data.plan_name} plan is not expired`,
            text: "Are you sure you want to renew?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add it!'
          }).then((result) => {
            if (result.isConfirmed) {
              if (answer_array[2] == "localhost:3000") {
                var plan = `http://localhost:8000/api/add_plan`;
              } else {
                var plan = `https://realestatebackend.cloud-dynasty.com/api/add_plan`;
              }
              const userId = sessionStorage.getItem("userId");

              const data = {
                plan_name: 'platinum',
                userId: userId
              };

              axios
                .post(plan, data)
                .then((res) => {
                  Swal.fire(
                    'activated',
                    'Your new plan is activated successfully',
                    'success'
                  )
                  setTimeout(function () {
                    document.getElementById("closeprice").click();
                    window.location.reload();
                  },2200)
                });
            }
          })
        }
      });
  }
  return (
    <>
      <section
        className={`plans ${isDashboard} ${setShow ? "" : "d-none"}`}
        id="price"
      >
          <i
          className={`fa fa-times ${isDashboard}`}
          aria-hidden="true"
          onClick={handleShow}
          id='closeprice'
        ></i>
        <h2 className="workH2">Choose the right plan for your team</h2>
        <div className="container">
          <div className="row mt-5 justify-content-evenly">
            <div className="col-md-3">
              <div className="main-container" onClick={silverplan}>
                {/* <div className="radio">
                  <input type="radio" name="pricing" onClick={addplan('silver')}/>
                </div> */}
                <div className="borderDiv">
                  <h2>Silver</h2>
                  <h4>upto 4 seats</h4>
                  <h3>free forever</h3>
                  <div className="line"></div>
                  <h5>Basic plan with:</h5>
                  <p>Up to 2 seats</p>
                  <p>Up to 1000 items</p>
                  <p>500 MB of storage</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="main-container" onClick={goldplan}>
                {/* <div className="radio">
                  <input type="radio" name="pricing" />
                </div> */}
                <div className="borderDiv yellow">
                  <h2>Gold</h2>
                  <h4>Total $40 /month</h4>
                  <h3>Billed annually</h3>
                  <div className="line"></div>
                  <h5>Includes Individual,plus:</h5>
                  <p>Unlimited free viewers</p>
                  <p>Unlimited items</p>
                  <p>Unlimited boards</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="main-container" onClick={platinumplan}>
                {/* <div className="radio">
                  <input type="radio" name="pricing" />
                </div> */}
                <div className="borderDiv green">
                  <h2>Platinum</h2>
                  <h4>Total $80 /month</h4>
                  <h3>Billed annually</h3>
                  <div className="line"></div>
                  <h5>Includes Basic,plus:</h5>
                  <p>Unlimited free viewers</p>
                  <p>Unlimited items</p>
                  <p>Unlimited boards</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h1 className="mt-5 mb-5">Compare plans and features</h1> */}
        {/* <div className="featurPlan">
          <div className="row borBottom">
            <div className="col-md-4">
              <div>
                <h2>Essentials</h2>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="pink">Silver</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="yellow">Gold</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p className="green">Platinum</p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>Seats</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>Up to 2 seats</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>Items</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>Up to 1000</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>Unlimited</p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>Unlimited boards</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>Up to 1000</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="pink">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="yellow">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p className="green">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>iOS and Android apps</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>1 week</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="pink">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="yellow">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p className="green">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>Unlimited free viewers</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>1 week</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="pink">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p className="yellow">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p className="green">
                  <i className="fa fa-check-circle"></i>
                </p>
              </div>
            </div>
          </div>
          <div className="row borBottom border-0">
            <div className="col-md-2">
              <div>
                <p>File storage</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>500 MB</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>500 MB</p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <p>5 GB</p>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <p>500 GB</p>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Pricing;
