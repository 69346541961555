const Footer = () => {
  return (
    <>
      <footer>
        <div className="landingFooter">
          <div className="container d-flex justify-content-between align-items-center p-4">
            <p className="m-0">Copyright 2022. All Rights Reserved</p>
            <div className="icons"></div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
